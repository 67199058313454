.news__wrapper {
    background-color: #f1f1f1;

}

.latest__news {
    background-color: white;
    font-size: 16px;
    @include padding();

    @include breakpoint($action) {
        font-size: 21px;
    }

}

.latest__news-date {
    margin-bottom: 0;
    color: $black;
}

.latest__news-title {
    color: $primary-color;
    font-size: 21px;
    margin-bottom: 12px;
}

.latest__news-excerpt {
    margin-bottom: 32px;
    width: 90%;
    color: #4C4C4C;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

.news__block {
	background-color: white;
	margin-top: 40px;
	.btn {
		margin-top: 30px;
	}
}

.news__info {
	padding: 25px 30px;
}

.news__image {
    margin-top: 20px;
    margin-bottom: 30px;
}

.news-share {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 40px 0 0;

    svg {
    	height: 25px;
    }

    li {
    	&:not(:last-child) {
	        margin-right: 15px;
	    }
    }

    a {
    	display: block;
    	margin-bottom: 0;
    }

    @include breakpoint($action) {
        margin: 0;
    }
}
