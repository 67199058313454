.project__list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.project__item {
	margin-top: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    background-color: grey;
    background-blend-mode: soft-light;
    color: white;

    padding-left: 30px;
    padding-right: 30px;

    &:hover,
    &:focus {
		.btn {
	        outline: none;
	        color: $primary-color;
	        &:before {
	            transform: translateX(0);
	        }
	        &:after {
	            border-color: $primary-color;
	        }
    	}
    }

    @include breakpoint($action) {
    	height: 140px;
    }
}


.project__title {
	font-size: 21px;
	display: block;
	@include breakpoint($action) {
		font-size: 31px;
		flex-grow: 1;
	}
}

.project__link {
	text-decoration: none;
    width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;

	.btn {
		margin-top: 30px;
	}

    @include breakpoint($action) {
		display: flex;
	    align-items: center;
	    padding-top: 0;
	    padding-bottom: 0;

	    .btn {
			margin-top: 0;
		}
    }
}

.project__header {
	background-color: white;
    padding: 35px 0px;

	h1 {
		color: $black;
		margin-bottom: 0;
	}
	h2 {
		color: grey;
		margin-bottom: 0;
	}
}

.project__wrapper {
	background-color: white;
	font-size: 21px;
}

.project__details,
.project__description {
	@include padding();
}

// .project__description {
// 	@include padding();
// }

.project__details {
	background-color: #828282;
	color: white;
	padding-left: 40px;
	padding-right: 40px;
	color: white;
}

.project__info {
	font-size: 21px;
	margin-bottom: 20px;

	h5 {
		color: $primary-color;
		margin-bottom: 0;
	}

	p {
		margin-bottom: 0;
	}

	.btn {
		margin-top: 40px;
	}
}
