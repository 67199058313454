.btn {
    max-width: 220px;
    width: 100%;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    display: inline-block;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 15px 80px 15px 25px;
    border: 1px solid;
    border-radius: 40px;
    transition: 0.3s all ease-in-out;
    text-decoration: none;
    background: none;
    position: relative;
    font-size: 16px;
    overflow: hidden;

    span {
        position: relative;
        z-index: 10;
    }

    @include breakpoint($action) {
        font-size: 21px;
    }

    &.disabled,
    &:disabled {
        opacity: .65;
    }


    &:before {
        content: '';
        transform: translateX(-100%);
        display: block;
        transition: all 0.3s;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &:focus,
    &:hover {
        outline: none;
        &:before {
            transform: translateX(0);
        }
    }


    &:after {
        content: '';
        position: absolute;
        overflow: hidden;
        display: inline-block;
        font-size: 3px;
        width: 4em;
        height: 4em;
        margin-top: -2em;
        top: 50%;
        border-top: 1px solid;
        border-right: 1px solid;
        transform: rotate(54deg) skew(20deg);
        right: 20px;

        @include breakpoint($action) {
            font-size: 5px;
        }
    }
}

.btn__primary {
    border-color: $primary-color;
    color: $primary-color;

    &:before {
        background-color: $primary-color;
    }

    &:after {
        border-color: $primary-color;
    }

    &:hover,
    &:focus {
        color: white;

        &:after {
            border-color: white;
        }
    }
}

.btn__secondary {
    border-color: $secondary-color;
    color: $secondary-color;

    &:before {
        background-color: $secondary-color;
    }

    &:after {
        border-color: $secondary-color;
    }

    &:hover,
    &:focus {
        color: white;

        &:after {
            border-color: white;
        }
    }
}

.btn__white {
    border-color: white;
    color: white;

    &:before {
        background-color: white;
    }

    &:after {
        border-color: white;
    }

    &:hover,
    &:focus {
        color: $primary-color;

        &:after {
            border-color: $primary-color;
        }
    }
}