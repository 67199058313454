.slider__wrapper {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
    background-position: center;
   height: 300px;

    @include breakpoint($action) {
	   height: 681px;
    }
}

.slider__info {
	position: absolute;
    bottom: 0;
    background-color: white;
    padding: 10px 25px;
    transform: skew(-15deg);
    right: 10%;

    h3 {
	    transform: skew(15deg);
        margin-bottom: 0;
    }
}