.main__nav {
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    position: relative;
    z-index: 10;

    opacity: 0;
    visibility: hidden;
    display: none;

    @include breakpoint($action) {
        flex-direction: row;
        opacity: 1;
        visibility: visible;
        display: flex;
    }
}

.nav-active {
    .main__nav {
        opacity: 1;
        visibility: visible;
        display: block;
    }
}

.nav__link {
    display: block;
    padding: 10px 30px;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background: none;
    border: 0;
    cursor: pointer;

    @include breakpoint($action) {
        &:not(:last-child) {
            border-right: 1px solid $black;
        }
    }
}

.nav__link-dropdown {
    &:hover + .nav__dropdown {
        opacity: 1;
        visibility: visible;
    }
}

.nav__dropdown {
    display: block;
    position: absolute;
    background-color: $primary-color;
    width: 100%;
    height: 100%;
    color: $black;
    top: 0;
    left: 0px;
    opacity: 0;

    z-index: 5;

    transition: all 0.2s ease-in-out;
    visibility: hidden;

    &:hover {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }

    ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        padding: 0;
        @include breakpoint($action) {
            flex-direction: row;
        }
    }
    @include breakpoint($action) {
        height: auto;
        top: 100%;
    }

}

.dropdown__link {
    display: block;
    padding: 10px 30px;
    text-decoration: none;
    word-wrap: normal;
    word-break: normal;
    hyphens: none;
    text-align: center;
}