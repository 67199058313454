.latest__tweet {
	background-color: $black;
	@include padding();
}

.twitter__handle {
    color: #fff;
    font-size: 21px;
}

#twitter {
	color: white;
	font-size: 21px;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
}