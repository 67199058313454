@charset "UTF-8";
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
  max-width: 100%; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; }

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

.wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .wrapper::after {
    clear: both;
    content: "";
    display: block; }
  @media (min-width: 60em) {
    .wrapper {
      padding-left: 60px;
      padding-right: 60px; } }
  @media (min-width: calc($wrapper + 120px)) {
    .wrapper {
      padding-left: 0;
      padding-right: 0; } }

@media (min-width: 60em) {
  .grid {
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px); }
    .grid:before, .grid:after {
      content: '';
      display: table;
      clear: both; } }

@media (min-width: 60em) {
  .g-oneThird {
    width: calc(33.33333% - 53.33333px);
    float: left;
    margin-left: 40px; }
  .g-twoThirds {
    width: calc(66.66667% - 66.66667px);
    float: left;
    margin-left: 40px; }
  .project__description {
    width: calc(58.33333% - 63.33333px);
    float: left;
    margin-left: 40px; }
  .project__details {
    width: calc(41.66667% - 56.66667px);
    float: left;
    margin-left: 40px; }
  .featured__link {
    width: calc(50% - 60px);
    float: left;
    margin-left: 40px; }
  .col__even {
    width: calc(50% - 60px);
    float: left;
    margin-left: 40px; } }

.col__even + .col__even,
.grid + .grid {
  margin-top: 40px; }
  @media (min-width: 60em) {
    .col__even + .col__even,
    .grid + .grid {
      margin-top: 0; } }

#map {
  height: 400px; }

body {
  font-family: "proxima-nova", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #000;
  line-height: 1.5; }

input, textarea, select, button {
  font-family: "proxima-nova", sans-serif; }

h1, .h1, h2, .h2 {
  font-size: 21px;
  color: #fdc533;
  font-weight: 300;
  margin-top: 0; }
  @media (min-width: 60em) {
    h1, .h1, h2, .h2 {
      font-size: 31px; } }

h3, h4, h5, h6 {
  font-size: 16px;
  font-weight: 300;
  margin-top: 0; }
  @media (min-width: 60em) {
    h3, h4, h5, h6 {
      font-size: 21px; } }

p, .p {
  margin-top: 0;
  font-size: inherit; }

ul.list__inline {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  ul.list__inline li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px; }
    ul.list__inline li:first-child {
      padding-left: 0; }
    ul.list__inline li:last-child {
      padding-right: 0; }

ul.list__unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-weight: 700; }

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-skip: ink;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto; }

a[href^="tel:"] {
  text-decoration: none; }

u {
  color: inherit;
  text-decoration: underline;
  text-decoration-skip: ink; }

.main__header {
  background-color: #272727;
  padding: 20px 0; }
  .main__header .wrapper {
    display: flex;
    align-items: center; }

.logo {
  flex-grow: 1; }
  .logo a {
    display: inline-block; }
  .logo img {
    max-width: 200px; }

.main__footer {
  background: #272727;
  color: #fff;
  padding: 25px 0;
  font-size: 14px; }
  .main__footer a {
    color: #fff; }
  .main__footer .col__even:last-of-type {
    text-align: right; }

.footer__logo {
  display: block;
  width: 200px;
  margin-bottom: 40px; }

.social__list {
  list-style-type: none;
  padding: 0;
  display: none; }
  @media (min-width: 60em) {
    .social__list {
      display: flex;
      align-items: center; }
      .social__list li:not(:last-child) {
        margin-right: 17px; } }

.accreds__list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 80px 0 50px; }
  .accreds__list h3 {
    margin-bottom: 0; }

.social__link {
  transition: 0.3s all ease-in-out; }
  .social__link .social__info {
    width: 30px;
    display: none;
    visibility: hidden;
    opacity: 0;
    margin-left: 10px;
    transform: translateX(65px);
    transition: 0.3s all ease-in-out; }
  .social__link:hover .social__info, .social__link:focus .social__info {
    max-width: 150px;
    width: 100%;
    display: inline-block;
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
    transition: 0.3s all ease-in-out; }

.social__icon {
  width: 25px;
  height: 25px;
  fill: white; }

.main__nav {
  background-color: #4C4C4C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  position: relative;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  display: none; }
  @media (min-width: 60em) {
    .main__nav {
      flex-direction: row;
      opacity: 1;
      visibility: visible;
      display: flex; } }

.nav-active .main__nav {
  opacity: 1;
  visibility: visible;
  display: block; }

.nav__link {
  display: block;
  padding: 10px 30px;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background: none;
  border: 0;
  cursor: pointer; }
  @media (min-width: 60em) {
    .nav__link:not(:last-child) {
      border-right: 1px solid #272727; } }

.nav__link-dropdown:hover + .nav__dropdown {
  opacity: 1;
  visibility: visible; }

.nav__dropdown {
  display: block;
  position: absolute;
  background-color: #fdc533;
  width: 100%;
  height: 100%;
  color: #272727;
  top: 0;
  left: 0px;
  opacity: 0;
  z-index: 5;
  transition: all 0.2s ease-in-out;
  visibility: hidden; }
  .nav__dropdown:hover {
    opacity: 1;
    transform: translateY(0);
    visibility: visible; }
  .nav__dropdown ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0; }
    @media (min-width: 60em) {
      .nav__dropdown ul {
        flex-direction: row; } }
  @media (min-width: 60em) {
    .nav__dropdown {
      height: auto;
      top: 100%; } }

.dropdown__link {
  display: block;
  padding: 10px 30px;
  text-decoration: none;
  word-wrap: normal;
  word-break: normal;
  hyphens: none;
  text-align: center; }

.hamburger {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  @media (min-width: 60em) {
    .hamburger {
      display: none;
      visibility: hidden; } }

.hamburger:hover,
.hamburger:focus {
  opacity: 0.7;
  outline: none; }

.nav-active .hamburger:hover {
  opacity: 0.7; }

.nav-active .hamburger .hamburger__inner,
.nav-active .hamburger .hamburger__inner::before,
.nav-active .hamburger .hamburger__inner::after {
  background-color: white; }

.hamburger__box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
  perspective: 80px; }

.hamburger__inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger__inner, .hamburger__inner::before, .hamburger__inner::after {
  width: 40px;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger__inner::before,
.hamburger__inner::after {
  content: "";
  display: block;
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger__inner::before {
  top: -10px; }

.hamburger__inner::after {
  bottom: -10px; }

.nav-active .hamburger .hamburger__inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }

.nav-active .hamburger .hamburger__inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.nav-active .hamburger .hamburger__inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.slider__wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px; }
  @media (min-width: 60em) {
    .slider__wrapper {
      height: 681px; } }

.slider__info {
  position: absolute;
  bottom: 0;
  background-color: white;
  padding: 10px 25px;
  transform: skew(-15deg);
  right: 10%; }
  .slider__info h3 {
    transform: skew(15deg);
    margin-bottom: 0; }

.intro {
  font-size: 24px;
  line-height: 1.3;
  padding: 30px 0;
  background-color: white;
  color: #646463; }
  @media (min-width: 60em) {
    .intro {
      font-size: 31px;
      padding: 100px 0; } }
  .intro .g-oneThird {
    text-align: right; }

.btn {
  max-width: 220px;
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 15px 80px 15px 25px;
  border: 1px solid;
  border-radius: 40px;
  transition: 0.3s all ease-in-out;
  text-decoration: none;
  background: none;
  position: relative;
  font-size: 16px;
  overflow: hidden; }
  .btn span {
    position: relative;
    z-index: 10; }
  @media (min-width: 60em) {
    .btn {
      font-size: 21px; } }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:before {
    content: '';
    transform: translateX(-100%);
    display: block;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .btn:focus, .btn:hover {
    outline: none; }
    .btn:focus:before, .btn:hover:before {
      transform: translateX(0); }
  .btn:after {
    content: '';
    position: absolute;
    overflow: hidden;
    display: inline-block;
    font-size: 3px;
    width: 4em;
    height: 4em;
    margin-top: -2em;
    top: 50%;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(54deg) skew(20deg);
    right: 20px; }
    @media (min-width: 60em) {
      .btn:after {
        font-size: 5px; } }

.btn__primary {
  border-color: #fdc533;
  color: #fdc533; }
  .btn__primary:before {
    background-color: #fdc533; }
  .btn__primary:after {
    border-color: #fdc533; }
  .btn__primary:hover, .btn__primary:focus {
    color: white; }
    .btn__primary:hover:after, .btn__primary:focus:after {
      border-color: white; }

.btn__secondary {
  border-color: #272727;
  color: #272727; }
  .btn__secondary:before {
    background-color: #272727; }
  .btn__secondary:after {
    border-color: #272727; }
  .btn__secondary:hover, .btn__secondary:focus {
    color: white; }
    .btn__secondary:hover:after, .btn__secondary:focus:after {
      border-color: white; }

.btn__white {
  border-color: white;
  color: white; }
  .btn__white:before {
    background-color: white; }
  .btn__white:after {
    border-color: white; }
  .btn__white:hover, .btn__white:focus {
    color: #fdc533; }
    .btn__white:hover:after, .btn__white:focus:after {
      border-color: #fdc533; }

.white__stroke {
  stroke: white; }

.white__fill {
  fill: white; }

.grey__stroke {
  stroke: #4C4C4C; }

.grey__fill {
  fill: #4C4C4C; }

.black__stroke {
  stroke: #272727; }

.black__fill {
  fill: #272727; }

.primary__stroke {
  stroke: #fdc533; }

.primary__fill {
  fill: #fdc533; }

.primary__color {
  color: #fdc533; }

.input__wrapper {
  position: relative;
  overflow: hidden; }
  .input__wrapper:before, .input__wrapper:after {
    content: '';
    display: block;
    width: calc(100% - 2px);
    height: 58px;
    position: absolute;
    top: 1px;
    left: 1px; }
  .input__wrapper:before {
    background-color: #fdc533;
    z-index: 10; }
  .input__wrapper:after {
    background-color: white;
    transform: translate(-100%, 0);
    display: block;
    transition: all 0.3s;
    pointer-events: none;
    z-index: 20; }
  .input__wrapper:focus:after, .input__wrapper:hover:after {
    transform: translate(1px, 0px); }
  .input__wrapper:focus input::placeholder, .input__wrapper:hover input::placeholder {
    color: #8c8c8c8c; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 15px;
  margin: 0;
  z-index: 30;
  background-color: transparent;
  border: 1px solid #fdc533;
  color: #272727; }
  [type='text']::placeholder, [type='password']::placeholder, [type='date']::placeholder, [type='datetime']::placeholder, [type='datetime-local']::placeholder, [type='month']::placeholder, [type='week']::placeholder, [type='email']::placeholder, [type='number']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='time']::placeholder, [type='url']::placeholder, [type='color']::placeholder, textarea::placeholder {
    color: white; }
  [type='text']:focus, [type='text']:hover, [type='password']:focus, [type='password']:hover, [type='date']:focus, [type='date']:hover, [type='datetime']:focus, [type='datetime']:hover, [type='datetime-local']:focus, [type='datetime-local']:hover, [type='month']:focus, [type='month']:hover, [type='week']:focus, [type='week']:hover, [type='email']:focus, [type='email']:hover, [type='number']:focus, [type='number']:hover, [type='search']:focus, [type='search']:hover, [type='tel']:focus, [type='tel']:hover, [type='time']:focus, [type='time']:hover, [type='url']:focus, [type='url']:hover, [type='color']:focus, [type='color']:hover, textarea:focus, textarea:hover {
    outline: none; }
    [type='text']:focus::placeholder, [type='text']:hover::placeholder, [type='password']:focus::placeholder, [type='password']:hover::placeholder, [type='date']:focus::placeholder, [type='date']:hover::placeholder, [type='datetime']:focus::placeholder, [type='datetime']:hover::placeholder, [type='datetime-local']:focus::placeholder, [type='datetime-local']:hover::placeholder, [type='month']:focus::placeholder, [type='month']:hover::placeholder, [type='week']:focus::placeholder, [type='week']:hover::placeholder, [type='email']:focus::placeholder, [type='email']:hover::placeholder, [type='number']:focus::placeholder, [type='number']:hover::placeholder, [type='search']:focus::placeholder, [type='search']:hover::placeholder, [type='tel']:focus::placeholder, [type='tel']:hover::placeholder, [type='time']:focus::placeholder, [type='time']:hover::placeholder, [type='url']:focus::placeholder, [type='url']:hover::placeholder, [type='color']:focus::placeholder, [type='color']:hover::placeholder, textarea:focus::placeholder, textarea:hover::placeholder {
      color: #8c8c8c8c; }
  [type='text']:disabled, [type='text'][readonly], [type='password']:disabled, [type='password'][readonly], [type='date']:disabled, [type='date'][readonly], [type='datetime']:disabled, [type='datetime'][readonly], [type='datetime-local']:disabled, [type='datetime-local'][readonly], [type='month']:disabled, [type='month'][readonly], [type='week']:disabled, [type='week'][readonly], [type='email']:disabled, [type='email'][readonly], [type='number']:disabled, [type='number'][readonly], [type='search']:disabled, [type='search'][readonly], [type='tel']:disabled, [type='tel'][readonly], [type='time']:disabled, [type='time'][readonly], [type='url']:disabled, [type='url'][readonly], [type='color']:disabled, [type='color'][readonly], textarea:disabled, textarea[readonly] {
    background-color: black;
    cursor: not-allowed; }

.input__wrapper + .input__wrapper [type='text'], .input__wrapper + .input__wrapper [type='email'], .input__wrapper + .input__wrapper textarea {
  border-top: none; }

textarea {
  max-width: 100%;
  height: 60px;
  resize: none;
  overflow: hidden; }

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: white;
  border-radius: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
  background-position: 100% center;
  background-repeat: no-repeat;
  line-height: normal;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border: 0;
  margin: 0 0 16px;
  font-family: "proxima-nova", sans-serif;
  font-size: 16px;
  color: #000;
  box-shadow: none;
  border-radius: 6px; }

input[type='radio'] {
  -webkit-appearance: radio; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline; }

input[type='submit'],
input[type='button'] {
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: auto;
  padding: 10px 30px; }

input[type='submit'],
input[type='button'] {
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: auto; }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

form .btn {
  margin-top: 40px; }

.hero {
  background-color: #fdc533;
  height: 100vh; }

.featured__projects {
  background-color: #f1f1f1;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 16px; }
  @media (min-width: 60em) {
    .featured__projects {
      font-size: 21px; } }

.featured__link {
  text-decoration: none;
  margin-bottom: 40px;
  display: block;
  word-wrap: normal;
  word-break: normal;
  hyphens: none; }
  .featured__link:hover .featured__image, .featured__link:focus .featured__image {
    transform: scale(1); }
  .featured__link:hover .btn, .featured__link:focus .btn {
    outline: none;
    color: white; }
    .featured__link:hover .btn:before, .featured__link:focus .btn:before {
      transform: translateX(0); }
    .featured__link:hover .btn:after, .featured__link:focus .btn:after {
      border-color: white; }

.featured__header {
  background-color: #272727;
  display: block;
  padding: 25px; }
  .featured__header h4 {
    color: white;
    margin-bottom: 0; }
  .featured__header h5 {
    color: #fdc533;
    margin-bottom: 0; }
  @media (min-width: 60em) {
    .featured__header {
      padding: 25px 35px; } }

.featured__image-wrapper {
  overflow: hidden;
  height: 270px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.featured__image {
  height: 270px;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1.1);
  transition: transform 3s ease-out; }

.featured__info {
  display: block;
  background-color: white;
  padding: 25px; }
  .featured__info .btn {
    display: block;
    margin-top: 30px; }
  @media (min-width: 60em) {
    .featured__info {
      padding: 25px 35px; } }

.latest__tweet {
  background-color: #272727;
  padding-top: 60px;
  padding-bottom: 60px; }

.twitter__handle {
  color: #fff;
  font-size: 21px; }

#twitter {
  color: white;
  font-size: 21px; }
  #twitter ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }

.news__wrapper {
  background-color: #f1f1f1; }

.latest__news {
  background-color: white;
  font-size: 16px;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 60em) {
    .latest__news {
      font-size: 21px; } }

.latest__news-date {
  margin-bottom: 0;
  color: #272727; }

.latest__news-title {
  color: #fdc533;
  font-size: 21px;
  margin-bottom: 12px; }

.latest__news-excerpt {
  margin-bottom: 32px;
  width: 90%;
  color: #4C4C4C;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto; }

.news__block {
  background-color: white;
  margin-top: 40px; }
  .news__block .btn {
    margin-top: 30px; }

.news__info {
  padding: 25px 30px; }

.news__image {
  margin-top: 20px;
  margin-bottom: 30px; }

.news-share {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 40px 0 0; }
  .news-share svg {
    height: 25px; }
  .news-share li:not(:last-child) {
    margin-right: 15px; }
  .news-share a {
    display: block;
    margin-bottom: 0; }
  @media (min-width: 60em) {
    .news-share {
      margin: 0; } }

.contact {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 16px; }
  .contact h6 {
    margin-bottom: 0; }
  .contact .grid {
    margin-top: 80px; }
  @media (min-width: 60em) {
    .contact {
      font-size: 21px; }
      .contact .col__even:last-of-type {
        padding-left: 100px; } }

.content {
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 21px; }
  .content p:last-of-type {
    margin-bottom: 0; }

.content-grey {
  background-color: #f1f1f1; }

.content-intro {
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 31px;
  line-height: 1.3; }
  @media (min-width: 60em) {
    .content-intro {
      max-width: 800px; } }

@media (min-width: 60em) {
  .content-body {
    max-width: 600px; } }

.link__box {
  background-color: white;
  position: relative;
  transition: all 0.2s ease;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; }
  .link__box:hover, .link__box:focus {
    background-color: #4C4C4C; }
    .link__box:hover span, .link__box:focus span {
      color: #fdc533; }
  .link__box img {
    width: auto;
    height: 140px;
    margin-right: 25px; }
  .link__box span {
    flex-grow: 1;
    font-size: 21px;
    margin-right: 25px;
    color: #646463; }

.project__list {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.project__item {
  margin-top: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  background-color: grey;
  background-blend-mode: soft-light;
  color: white;
  padding-left: 30px;
  padding-right: 30px; }
  .project__item:hover .btn, .project__item:focus .btn {
    outline: none;
    color: #fdc533; }
    .project__item:hover .btn:before, .project__item:focus .btn:before {
      transform: translateX(0); }
    .project__item:hover .btn:after, .project__item:focus .btn:after {
      border-color: #fdc533; }
  @media (min-width: 60em) {
    .project__item {
      height: 140px; } }

.project__title {
  font-size: 21px;
  display: block; }
  @media (min-width: 60em) {
    .project__title {
      font-size: 31px;
      flex-grow: 1; } }

.project__link {
  text-decoration: none;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px; }
  .project__link .btn {
    margin-top: 30px; }
  @media (min-width: 60em) {
    .project__link {
      display: flex;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0; }
      .project__link .btn {
        margin-top: 0; } }

.project__header {
  background-color: white;
  padding: 35px 0px; }
  .project__header h1 {
    color: #272727;
    margin-bottom: 0; }
  .project__header h2 {
    color: grey;
    margin-bottom: 0; }

.project__wrapper {
  background-color: white;
  font-size: 21px; }

.project__details,
.project__description {
  padding-top: 60px;
  padding-bottom: 60px; }

.project__details {
  background-color: #828282;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
  color: white; }

.project__info {
  font-size: 21px;
  margin-bottom: 20px; }
  .project__info h5 {
    color: #fdc533;
    margin-bottom: 0; }
  .project__info p {
    margin-bottom: 0; }
  .project__info .btn {
    margin-top: 40px; }

.iframe__wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-top: 40px; }

.iframe__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }
