.social__list {
	list-style-type: none;
	padding: 0;
	display: none;

	@include breakpoint($action) {
		display: flex;
		align-items: center;

		li {
			&:not(:last-child) {
				margin-right: 17px;
			}
		}
	}
}

.accreds__list {
	display: flex;
    justify-content: space-around;
    align-items: center;
	list-style-type: none;
	padding: 0;
    margin: 80px 0 50px;

    h3 {
    	margin-bottom: 0;
    }
}

.social__link {
	// display: flex;
 //    align-items: center;
	transition: 0.3s all ease-in-out;

	.social__info {
		width: 30px;
		display: none;
		visibility: hidden;
		opacity: 0;
		margin-left: 10px;
	    transform: translateX(65px);
	    transition: 0.3s all ease-in-out;
	}

	&:hover,
	&:focus {
		.social__info {
			max-width: 150px;
			width: 100%;
			display: inline-block;
			visibility: visible;
			opacity: 1;
		    transform: translateX(0px);
		    transition: 0.3s all ease-in-out;
		}
	}
}

.social__icon {
	width: 25px;
	height: 25px;
    fill: white;
}