.contact {
	background-color: white;
	@include padding();
	font-size: 16px;

	h6 {
		margin-bottom: 0;
	}

	.grid {
		margin-top: 80px;
	}

	@include breakpoint($action) {
		font-size: 21px;

		.col__even {
			&:last-of-type {
				padding-left: 100px;
			}
		}
	}
}