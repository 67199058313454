// - - - - - - - - - - - - - - - - - - -
// - - typography
// global typography styles

body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    color: $base-font-color;
    line-height: $base-line-height;
}

input, textarea, select, button {
    font-family: $base-font-family;
}

h1, .h1, h2, .h2 {
    font-size: 21px;
    color: $primary-color;
    font-weight: $heading-font-weight;
    margin-top: 0;
    @include breakpoint($action) {
        font-size: 31px;
    }
}

h3, h4, h5, h6 {
    font-size: 16px;
    font-weight: $heading-font-weight;
    margin-top: 0;
    @include breakpoint($action) {
        font-size: 21px;
    }
}

p, .p {
    margin-top: 0;
	font-size: inherit;
}

// LISTS

ul.list__inline {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
}

ul.list__unstyled {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-weight: 700;
}

a {
    color: inherit;
    text-decoration: underline;
    text-decoration-skip: ink;

    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

a[href^="tel:"] {
    text-decoration: none;
}

u {
    color: inherit;
    text-decoration: underline;
    text-decoration-skip: ink;
}