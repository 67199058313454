// Colours
// All colour variable names should come from http://chir.ag/projects/name-that-color/


// Social Media Colors
$social-colors: (
  twitter:    #00ACED,
  youtube:    #CD201F,
  pinterest:  #CB2027,
  github:     #333333,
  dribbble:   #EA4C88,
  instagram:  #517FA4,
  facebook:   #3B5999
);

// to use this color: map-get($social-colors, twitter);

$primary-color: #fdc533;
$secondary-color: #272727;
$black:    #272727;
$grey:    #4C4C4C;


// Typography
$base-font-family: "proxima-nova",sans-serif;;
$base-font-size: 16px;
$base-font-weight: 300;
$base-font-color: #000;
$base-line-height: 1.5;

$heading-font-family: $base-font-family;
$heading-font-weight: 300;



// Grid

$neat-grid: (
  columns: 12,
  gutter: 40px,
);

$neat-grid-five: (
  columns: 10,
  gutter: 20px,
);


// Media Queries
@include breakpoint-set('to ems', true);

$nas: 500px;
$wutang: 768px;
$action: 960px;
$dre: 1200px;
$kendrick: 1440px;
$biggie: 1600px;
$wrapper: $kendrick;