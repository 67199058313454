.main__header {
	background-color: $black;
	padding: 20px 0;
	.wrapper {
		display: flex;
		align-items: center;
	}
}

.logo {
	flex-grow: 1;
	a {
		display: inline-block;
	}

	img {
		max-width: 200px;
	}
}