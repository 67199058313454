.link__box {
	background-color: white;
    position: relative;
    transition: all 0.2s ease;
    margin-top: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    &:hover,
    &:focus {
    	background-color: $grey;
    	span {
    		color: $primary-color;
    	}
    }

    img {
    	width: auto;
	    height: 140px;
    	margin-right: 25px;
    }

    span {
		flex-grow: 1;
		font-size: 21px;
		margin-right: 25px;
		color: #646463;
    }
}