.intro {
    font-size: 24px;
    line-height: 1.3;
    padding: 30px 0;
    background-color: white;
    color: #646463;

    @include breakpoint($action) {
        font-size: 31px;
    	padding: 100px 0;
    }

    .g-oneThird {
    	text-align: right;
    }
}