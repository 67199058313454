.featured__projects {
    background-color: #f1f1f1;
    @include padding();
    font-size: 16px;

    @include breakpoint($action) {
    	font-size: 21px;
    }
}

.featured__link {
	text-decoration: none;
	margin-bottom: 40px;
	display: block;
	word-wrap: normal;
    word-break: normal;
    hyphens: none;

    &:hover,
    &:focus {
    	.featured__image {
    		transform: scale(1);
    	}

    	.btn {
	        outline: none;
	        color: white;
	        &:before {
	            transform: translateX(0);
	        }
	        &:after {
	            border-color: white;
	        }
    	}
    }
}

.featured__header {
	background-color: $black;
	display: block;
	padding: 25px;

	h4 {
		color: white;
		margin-bottom: 0;
	}

	h5 {
		color: $primary-color;
		margin-bottom: 0;
	}

	@include breakpoint($action) {
        padding: 25px 35px;
    }
}

.featured__image-wrapper {
	overflow: hidden;
	height: 270px;
	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.featured__image {
    height: 270px;
    background-size: cover;
    background-repeat: no-repeat;
    transform: scale(1.1);
    transition: transform 3s ease-out;
}

.featured__info {
	display: block;
	background-color: white;
	padding: 25px;

	.btn {
		display: block;
		margin-top: 30px;
	}

	@include breakpoint($action) {
        padding: 25px 35px;
    }
}