.input__wrapper {
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
        content: '';
        display: block;
        width: calc(100% - 2px);
        height: 58px;
        position: absolute;
        top: 1px;
        left: 1px;
    }
    &:before {
       background-color: $primary-color;
       z-index: 10;
    }

    &:after {
        background-color: white;
        transform: translate(-100%, 0);
        display: block;
        transition: all 0.3s;

        pointer-events: none;
        z-index: 20;
    }

    &:focus,
    &:hover {
        &:after {
            transform: translate(1px, 0px);
        }
        input {
            &::placeholder {
                color: #8c8c8c8c;
            }
        }
    }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding: 15px;
    margin: 0;
    z-index: 30;


    background-color: transparent;
    border: 1px solid $primary-color;
    color: $black;

    &::placeholder {
        color: white;
    }

    &:focus,
    &:hover {
        outline: none;
        &::placeholder {
            color: #8c8c8c8c;
        }
    }

    // Disabled/readonly state
    &:disabled,
    &[readonly] {
        background-color: black;
        cursor: not-allowed;
    }
}

.input__wrapper + .input__wrapper {
    [type='text'], [type='email'], textarea {
        border-top: none;
    }
}

textarea {
    max-width: 100%;
    height: 60px;
    resize: none;
    overflow:hidden
}

select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-color: white;
    border-radius: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
    background-position: 100% center;
    background-repeat: no-repeat;
    line-height: normal;

    width: 100%;
    height: 40px;
    padding: 0 5px;
    border: 0;
    margin: 0 0 $base-font-size;

    font-family: $base-font-family;
    font-size: $base-font-size;
    color: $base-font-color;
    box-shadow: none;
    border-radius: 6px;
}

input[type='radio'] {
    -webkit-appearance: radio;
}

[type='checkbox'] + label,
[type='radio'] + label {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 0;
    vertical-align: baseline;
}

input[type='submit'],
input[type='button'] {
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: auto;
      padding: 10px 30px;
}

input[type='submit'],
input[type='button'] {
    border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: auto;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #333;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

form .btn {
    margin-top: 40px;
}
