.iframe__wrapper {
	position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    margin-top: 40px;
}

.iframe__embed {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}