// Typography mixins

@function calculateRem($size) {
	$remSize: $size / 18px;
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

@mixin placeholder {

    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}


// Positing mixins
@mixin overlay() {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}


@mixin center($position) {
	position: absolute;

	@if $position == 'vertical' {
		top: 50%;
		transform: translateY(-50%);
	}
	@else if $position == 'horizontal' {
		left: 50%;
		transform: translate(-50%);
	}
	@else if $position == 'both' {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}


// Colors

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}


@mixin padding() {
	padding-top: 60px;
	padding-bottom: 60px;
}