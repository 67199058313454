.content {
	@include padding();
	font-size: 21px;
	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.content-grey {
	background-color: #f1f1f1;
}

.content-intro {
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 31px;
    line-height: 1.3;
	@include breakpoint($action) {
		max-width: 800px;
	}
}

.content-body {
	@include breakpoint($action) {
		max-width: 600px;
	}
}