.white__stroke {
	stroke: white;
}
.white__fill {
	fill: white;
}

.grey__stroke {
	stroke: $grey;
}
.grey__fill {
	fill: $grey;
}

.black__stroke {
	stroke: $black;
}
.black__fill {
	fill: $black;
}

.primary__stroke {
	stroke: $primary-color;
}
.primary__fill {
	fill: $primary-color;
}

.primary__color {
	color: $primary-color;
}
