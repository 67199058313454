.hamburger {
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @include breakpoint($action) {
        display: none;
        visibility: hidden;
    }
}

.hamburger:hover,
.hamburger:focus {
    opacity: 0.7;
    outline: none;
}

.nav-active .hamburger:hover {
    opacity: 0.7;
}

.nav-active .hamburger .hamburger__inner,
.nav-active .hamburger .hamburger__inner::before,
.nav-active .hamburger .hamburger__inner::after {
    background-color: white;
}

.hamburger__box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
    perspective: 80px;
}

.hamburger__inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger__inner, .hamburger__inner::before, .hamburger__inner::after {
    width: 40px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger__inner::before,
.hamburger__inner::after {
    content: "";
    display: block;
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger__inner::before {
    top: -10px;
}

.hamburger__inner::after {
    bottom: -10px;
}

.nav-active .hamburger .hamburger__inner {
    background-color: transparent !important;
    transform: rotateX(180deg) rotateY(180deg);
}

.nav-active .hamburger .hamburger__inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg);
}

.nav-active .hamburger .hamburger__inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
}
