// - - - - - - - - - - - - - - - - - - -
// - - layout
// global layout classes - height, width, padding, margin, etc.


// Using Neat grid for documentation
// https://neat.bourbon.io/docs/latest/


// @include grid-column(12);
// @include grid-column(10, $neat-grid-five);

.wrapper {
	@include grid-container();
	max-width: $wrapper;
	margin-left: auto;
	margin-right: auto;

	padding-left: 20px;
	padding-right: 20px;

	@include breakpoint($action) {
		padding-left: 60px;
		padding-right: 60px;
	}

	// minus out the padding
	@include breakpoint(calc($wrapper + 120px)) {
		padding-left: 0;
		padding-right: 0;
	}
}

@include breakpoint($action) {
	.grid {
		@include grid-collapse;
		&:before,
		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}
}

@include breakpoint($action) {
	.g-oneThird {
		@include grid-column(4);
	}

	.g-twoThirds {
		@include grid-column(8);
	}

	.project__description {
		@include grid-column(7);
	}

	.project__details {
		@include grid-column(5);
	}

	.featured__link {
		@include grid-column(6);
	}

	.col__even {
		@include grid-column(6);
	}
}

.col__even + .col__even,
.grid + .grid {
    margin-top: 40px;
    @include breakpoint($action) {
        margin-top: 0;
    }
}



#map {
    height: 400px;
}