.main__footer {
    background: #272727;
    color: #fff;
    padding: 25px 0;
    font-size: 14px;

    a {
    	color: #fff;
    }

    .col__even {
    	&:last-of-type {
    		text-align: right;
    	}
    }
}

.footer__logo {
	display: block;
	width: 200px;
	margin-bottom: 40px;
}